import React, { useContext, useState } from 'react'
import { useWallet } from '@binance-chain/bsc-use-wallet'
import { allLanguages } from 'config/localisation/languageCodes'
import { LanguageContext } from 'contexts/Localisation/languageContext'
import useTheme from 'hooks/useTheme'
import { usePriceEbenUsd } from 'state/hooks'
import { ConnectorId, Menu as UikitMenu } from '@pancakeswap-libs/uikit'
import { connWallt } from 'utils/wallet'
import useI18n from 'hooks/useI18n'
import useProfile from 'hooks/useProfile'
import config from './config'

const Menu = (props) => {
  const { account, connect, reset,status } = useWallet()
  const { selectedLanguage, setSelectedLanguage } = useContext(LanguageContext)
  const { isDark, toggleTheme } = useTheme()
  const ebenPriceUsd = usePriceEbenUsd()
  const t=useI18n();
  const profile = useProfile();
  const [connector,setConnector]= useState('')
  return (
    <UikitMenu
      connectorId={connector}
      account={account}
      login={(connectorId: ConnectorId)=>{setConnector(connectorId);connWallt(connectorId,connect,status)}}
      logout={reset}
      isDark={isDark}
      toggleTheme={toggleTheme}
      currentLang={selectedLanguage && selectedLanguage.code}
      langs={allLanguages}
      setLang={setSelectedLanguage}
      cakePriceUsd={ebenPriceUsd.toNumber()}
      links={config(t)}
      priceLink="https://chartpro.benswap.cash/?token=0x77cb87b57f54667978eb1b199b28a0db8c8e1c0b"
      profile={profile}
      {...props}
    />
  )
}

export default Menu
