/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import farmsConfig from 'config/constants/farms'
import {getBchPrice} from 'utils'
import fetchFarms from './fetchFarms'
import {
  fetchFarmUserEarnings,
  fetchFarmUserAllowances,
  fetchFarmUserTokenBalances,
  fetchFarmUserStakedBalances,
} from './fetchFarmUser'
import { FarmsState, Farm } from '../types'

const initialState: FarmsState = { data: [...farmsConfig], bchPrice: 0}

export const farmsSlice = createSlice({
  name: 'Farms',
  initialState,
  reducers: {
    setFarmsPublicData: (state, action) => {
      const liveFarmsData: Farm[] = action.payload;
      
      for (let i=0; i<liveFarmsData.length; i++) {
        const lf = liveFarmsData[i];
        const s = state.data.find((f) => f.pid === lf.pid);
        if (s) Object.assign(s, lf);
        else {
          state.data = liveFarmsData;
          break;
        }
      }
    },
    setFarmUserData: (state, action) => {
      const { arrayOfUserDataObjects } = action.payload
      arrayOfUserDataObjects.forEach((userDataEl) => {
        const { index } = userDataEl
        state.data[index] = { ...state.data[index], userData: userDataEl }
      })
    },
    setBchPrice: (state, action) => {
      state.bchPrice = action.payload;
    },
  },
})

// Actions
export const { setFarmsPublicData, setFarmUserData, setBchPrice} = farmsSlice.actions

// Thunks
export const fetchFarmsPublicDataAsync = () => async (dispatch) => {
  await Promise.all([
    fetchFarms().then((farms)=> { dispatch(setFarmsPublicData(farms)); }),
    getBchPrice().then((p)=>{
      dispatch(setBchPrice(Number(p)));
    })
  ]);
}

export const fetchFarmUserDataAsync = (account, farms?) => async (dispatch) => {
  if (farms && farms.length < 5) return;
  const userFarmAllowances = await fetchFarmUserAllowances(account, farms)
  const userFarmTokenBalances = await fetchFarmUserTokenBalances(account, farms)
  const userStakedBalances = await fetchFarmUserStakedBalances(account, farms)
  const userFarmEarnings = await fetchFarmUserEarnings(account, farms)

  const arrayOfUserDataObjects = userFarmAllowances.map((farmAllowance, index) => {
    return {
      index,
      allowance: userFarmAllowances[index],
      tokenBalance: userFarmTokenBalances[index],
      stakedBalance: userStakedBalances[index],
      earnings: userFarmEarnings[index],
    }
  })

  dispatch(setFarmUserData({ arrayOfUserDataObjects }))
}

export default farmsSlice.reducer
