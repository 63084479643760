export default {
  lpFactory: {
    10000: '0x8d973bAD782c1FFfd8FcC9d7579542BA7Dd0998D'
  },
  wbch: {
    10000: '0x3743eC0673453E5009310C727Ba4eaF7b3a1cc04'
  },
  flexUSD: {
    10000: '0x7b2B3C5308ab5b2a1d9a94d20D35CCDf61e05b72'
  },
  cake: {
    10000: '0x77CB87b57F54667978Eb1B199b28a0db8C8E1c0B',
    56: '0x8173dDa13Fd405e5BcA84Bd7F64e58cAF4810A32',
    97: '',
  },
  eben: {
    10000: '0x77CB87b57F54667978Eb1B199b28a0db8C8E1c0B'
  },
  gben: {
    56: '0x8173dDa13Fd405e5BcA84Bd7F64e58cAF4810A32',
    97: '',
  },
  masterChef: {
    56: '0x03245d87295cd0783E1f10a2EA54F9E80B726aF8',
    97: '',
    10000: '0xDEa721EFe7cBC0fCAb7C8d65c598b21B6373A2b6'
  },
  wbnb: {
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    97: '',
  },
  lottery: {
    56: '0xB8c1b020921155373Ac667a8Aa29A4b0F3637a80',
    97: '',
    10000: '0xd24d70B77db78bF8Bb7017a94be575Fb172C6C15'
  },
  lotteryNFT: {
    56: '0x3d4175471cB164541bf811aC041d7D23E712937e',
    97: '',
    10000: '0xFc82061Fe93C1DeF8D6dD5D6865B383C543a1315'
  },
  temple: {
    56: '0x5e5C3AEC925Ae281DAFe8Ca5777E7Ab021De755c',
    97: '',
  },
  mulltiCall: {
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
    10000: '0x1b38EBAd553f218e2962Cb1C0539Abb5d6A37774'
  },
  preseller: {
    10000: '0x7190D576214deEb04b5E6DdCf11E8b1Bfc396791'
  },
  iboFactory: {
    10000: '0x9FE4b647609F77B5e23Cdbe85ea89064125386eE'
  },
  benSwapLpValidator: {
    10000: '0x7888872828c92e8cF0d7bbc04B52CFe6fDD4Ffdf'
  },
  benLockFactory: {
    10000: '0x5507194a62c02Ac47eD6da5Ab178C3cf62d4CEF4'
  },
  sep20Factory: {
    10000: '0x73410fa5CE7401272055a45DCaff7170D343433a'
  },
  sep20TemplateBasic: {
    10000: '0xE8280F2cd8728DA467c8Ec101241ef39f0041d35'
  },
  sep20TemplateLiquidityGenerator: {
    10000: '0x6a737C379DaE77Bb085cA0c2902360A74Bf982A2'
  },
  sep20TemplateAnnuity: {
    10000: '0xF791b30349DCf95b19ED7011808EA7adEad98EAE'
  },
  sep20TemplateMultiMint: {
    10000: '0x6126F03384D805567FD5A4879D7A1B1a3Bfd99F4'
  },
  sep20TemplateInscriptions: {
    10000: '0xe657F586F107b824A4eBD01E999c06eDB2e73621'
  },
  ranchFactory: {
    10000: '0x15F252B98e9195089D12e283Cd8388b1687C6b8E'
  },
  iboV2Factory: {
    10000: '0x455D6EAD2bbB3553Be12A9528054A41136FeB731'
  },
  iboV2SimpleSendingOfferingTokensConclusionStrategy: {
    10000: '0x322de556F8d2368E47753C67B73F00e954fE9b70'
  },
  iboV2SimpleSendingRaisingTokensConclusionStrategy: {
    10000: '0x448b2DdA9928bFE3b4605C725Ee28b5f2Ef75792'
  },
  iboV2TimeLockRaisingTokensConclusionStrategy: {
    10000: '0x3c71f8DBcf49F793f381787119E9E33B6eaa5DE6'
  },
  iboV2TimeLockOfferingTokensConclusionStrategy: {
    10000: '0x9B91e9d6Fe836e8827c35815A8e701a53fA2D88c'
  },
  iboV2AddRaisingTokensToLiquidityAndLockConclusionStrategy: {
    10000: '0xa230368E7F85c35d0195728e149e1d80D7858995'
  },
  iboV2AddRaisingTokensToLiquidityAndSendConclusionStrategy: {
    10000: '0x7303A4f8e9B1abCa1eC4A81382A9D9F125c7a155'
  },
  iboV2SendingOfferingTokensToRaisingTokenConclusionStrategy: {
    10000: '0x18f08fdE3d616f5DeaF8b37d6abF25F5386732bB'
  },
  candyHouse: {
    10000: '0xe81E059d7Dd6B0b99c6b3344325D9AbC92E7f2e3'
  },
  ebenGov: {
    10000: '0x40ea6ca4f670efFeDC269cD01d49e57e481206FC'
  },
  airdropper: {
    10000: '0xc13d273485B4Db1C5DC3FBe7f403E7b52CA3BFAd'
  },
  pokeBen: {
    10000: '0xFDEd6cD4B88a24e00d9Ea242338367fe734CBff5'
  },
  pokeBenHuntV2: {
    10000: '0x2f9EdbEA43256546a9269598a033d65E67Ef1B9b'
  },
  pokeBenNftNameExtension: {
    10000: '0xfaf933c76E2ae21a63DF65bbD3888B3FB2Fc43Ae'
  },
  pokeBenItem: {
    10000: '0x335bF14Af7c6b2993434bB700AF0f1Afcf27d782'
  },
  pokeBenAbilityExt: {
    10000: '0x23662b10e4067480A39d337BA08ac898B90b7F80'
  },
  pokeBenAbilityTrainingRoom: {
    10000: '0x1583aCA9Ad70d6CfF8e7bA27957092CC82310c00'
  },
  pokeBenEvoluterV1: {
    10000: '0x201fA5675EF8b87662066F802f7cACE876102297'
  },
  pokeBenTeamExt: {
    10000: '0x481E2ab61868F6a8DBe49b386a1fcE17E1Cd2B23'
  },
  pokeBenPvpExt: {
    10000: '0xEb04747bceA46eBD61D441389D0147c279BC3E5f'
  },
  pokeBenShop:{
    10000: "0x159f884d87cE296a09BBA33Ff297B315Ad509581"
  },
  pokeBenHero:{
    10000: "0x014da337dd4e097935797602332a4649c3F436c1"
  },
  pokeBenHeroPartAppraiser: {
    10000: '0xD69D5Fe6c95ef8aF143f3070D1370C88e4BE7512'
  },
  pokeBenHeroBeautician: {
    10000: '0x108d5B2Eae269eBeB83a02a77E3da67CB7CD684d'
  },
  farmVoting: {
    10000: '0xc0E541a34B0E1c6fFf51b1780DE899873B6C81f7'
  },
  NFTEX:{
    10000: "0x78d9F0fc19234bC3D8baCB538DeF3C313044B9e5"
  },
  nftFactorySingle:{
    10000: "0xF8E0755544ED3133182085773213727Eb05258bd"
  },
  fogHero:{
    10000: "0x1BA5f2342bAA572B3066aE2A6eC1D8DD44812A4B"
  },
  fogItem:{
    10000: "0x2a453B2A40734760De87E56cfc01CF5146a7D006"
  },
  tokenBurner:{
    10000: "0x71D9C349e35f73B782022d912B5dADa4235fDa06"
  },
  pokeBenCrystal:{
    10000: "0x7fa2DC7F8671544E301085CB76FfDA19c78AcD75"
  },
  charities:{
    10000: "0x3e755F66dC5EC7eeCDD981ed626ECB17c7a5D235"
  },
  cemeteryController:{
    10000: "0x01cE3fc62d76C30B5Db96C2d70354B84df29df5E"
  },
  cemeteryNft:{
    10000: "0x850C860C9288Cd4e8bfcEff46ebCA4E358563d91"
  },
  nftFactorySeries:{
    10000: "0x548830A21FF24E8B90415e71F268957535f54B8e"
  },
  nftTemplateBasic:{
    10000:"0x1A9C7d74F2aac2E4026f951d7C7ccEf83Eb1F9aE"
  },
  nftTemplateSimple:{
    10000:"0x9e18f36ADA68e95250d54de79dC4BD86751290b3"
  },
}
