import random from 'lodash/random'

// Array of available nodes to connect to
const nodes = [process.env.REACT_APP_NODE_BACKUP2]

const rpcNodes = {
  '0': process.env.REACT_APP_NODE_1,
  '1': process.env.REACT_APP_NODE_BACKUP1,
  '2': process.env.REACT_APP_NODE_BACKUP2,
  '3': process.env.REACT_APP_NODE_BACKUP3,
  '4': process.env.REACT_APP_NODE_BACKUP4,
  '5': process.env.REACT_APP_NODE_BACKUP5
}

const rpcNodeCacheKey = "USER_SELECTED_RPC_NODE_23081821";
const getOverride = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const p = urlParams.get('rpcnode');
  if (p && rpcNodes[p]) {
      localStorage.setItem(rpcNodeCacheKey, p);
      return p;
  }
  return localStorage.getItem(rpcNodeCacheKey);
}
const getNodeUrl = () => {
  const q = getOverride();
  if (q && rpcNodes[q]) return rpcNodes[q];

  const randomIndex = random(0, nodes.length - 1);
  return nodes[randomIndex];
}


export default getNodeUrl
