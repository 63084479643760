import { AbiItem } from 'web3-utils'
import contracts from 'config/constants/contracts'
import { FarmConfig, QuoteToken } from 'config/constants/types'
import { getWeb3 } from 'utils/web3'
import { getMasterChefAddress, isAddressMatch, getWbchAddress, getBenSwapLpValidatorAddress } from 'utils/addressHelpers'
import abiISingleAddressValidator from 'config/abi/iSingleAddressValidator.json'
import masterChef from 'config/abi/masterchef.json'
import erc20 from 'config/abi/erc20.json'
import abiPair from 'config/abi/pair.json'
import multicall from 'utils/multicall'
import { baseTokens } from './lpUtils'

const web3 = getWeb3();
const contract = new web3.eth.Contract((masterChef as unknown) as AbiItem, getMasterChefAddress());
let config;

const settings = {
    0: {
        risk: 5,
        priority: 9999,
    },
    1: {
        risk: 5,
        priority: 9999,
    }, 
    2: {
        risk: 2,
        priority: 200,
    },
    12: {
        tag: "ibo"
    },
    15: {
        tag: "ibo"
    },
    18: {
        tag: "rugpull"
    },
    21: {
        risk: 2,
        priority: 2000,
        tag: "core"
    },
    22: {
        risk: 5,
        priority: 1000,
        tag: "ecosystem"
    },
    23: {
        risk: 2,
        priority: 1999,
        tag: "core"
    },
    24: {
        risk: 2,
        priority: 1998,
        tag: "core"
    },
    27: {
        tag: "ibo"
    },
    35: {
        risk: 2,
        priority: 2000,
        tag: "core"
    },
}

const getSortedTokens = (token1, token2) => {
    for(let i=0; i<baseTokens.length; i++) {
        if (isAddressMatch(token1, baseTokens[i])) return [token2, token1];
        if (isAddressMatch(token2, baseTokens[i])) return [token1, token2];
    }
    return [token2, token1];
}

export const getFarmConfig = async () => {
    if (config && config.length) return config;

    const poolLength = await contract.methods.poolLength().call();

    const calls1 = [];
    for (let i = 0; i < poolLength; i++) {
        calls1.push({
            address: getMasterChefAddress(),
            name: 'poolInfo',
            params: [i],
        });
    }

    const result1 = await multicall(masterChef, calls1);

    const call2Master = [];
    let call2 = [];
    const raw = result1.map((r,i)=> {
        if (call2.length > 18) {
            call2Master.push(call2);
            call2 = [];
        }
        call2.push({
            address: getBenSwapLpValidatorAddress(),
            name: 'validate',
            params: [r.lpToken],
        });

        return {
            pid: i,
            risk: 5,    // not used
            lpAddresses: {
                10000: r.lpToken
            },
            priority: r.allocPoint,
            ...(settings[i]||{})
        };
    });
    call2Master.push(call2);

    let result2 = [];
    for (let i=0 ;i<call2Master.length;i++) {
        const r = await multicall(abiISingleAddressValidator, call2Master[i]);
        result2= result2.concat(r);
    }

    const calls3 = []
    for (let i = 0; i < poolLength; i++) {
        if (result2[i][0]) {
            calls3.push({
                address: result1[i].lpToken,
                name: 'token0'
            }, {
                address: result1[i].lpToken,
                name: 'token1'
            })
        } else {
            Object.assign(raw[i], {
                isTokenOnly : true,
                tokenAddresses: {
                    10000: result1[i].lpToken,
                },
                lpAddresses: {
                    10000: '0x0D4372aCc0503Fbcc7EB129e0De3283c348B82c3', // EBEN-BCH LP as mock
                },
                quoteTokenSymbol: QuoteToken.BCH,
                quoteTokenAdresses: contracts.wbch,
            })
        }
    }

    const result3 = await multicall(abiPair, calls3);

    const tokens = result3.map(r=>r[0]).concat(raw.filter(r=>r.isTokenOnly).map(r=>r.tokenAddresses[10000]));
    const calls4 = tokens.map(t=> {
        return {
            address: t,
            name: 'symbol'
        }
    });

    const result4 = await multicall(erc20, calls4);
    const symbols = {};
    tokens.forEach((t,i) => {
        symbols[t] = result4[i][0];
    });

    symbols[getWbchAddress()] = 'BCH';
    const getSingleTokenSymbol = (address) => {
        if (address === getWbchAddress()) return 'WBCH';
        return symbols[address];
    }
    let z = 0;
    const getDisplaySymbol = (address) => {
        if (address === getWbchAddress()) return "SBCH";
        return symbols[address];
    }
    config = raw.map((r,i)=>{
        if (r.isTokenOnly) {
            return {
                lpSymbol: getSingleTokenSymbol(r.tokenAddresses[10000]),
                tokenSymbol: getSingleTokenSymbol(r.tokenAddresses[10000]),
                ...r
            };
        }
        const ts = getSortedTokens(tokens[z++], tokens[z++]);
        return {
            lpSymbol: `${getDisplaySymbol(ts[0])}-${getDisplaySymbol(ts[1])} LP`,
            tokenSymbol: symbols[ts[0]],
            tokenAddresses: { 10000: ts[0] },
            quoteTokenSymbol: symbols[ts[1]],
            quoteTokenAdresses: { 10000: ts[1] },
            ...r
        };
    }).sort((a,b)=>{
        return (b.priority||0) - (a.priority||0)
    });

    return config;
}
