import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    risk: 5,
    lpSymbol: 'EBEN-SBCH LP',
    lpAddresses: {
      10000: '0x0D4372aCc0503Fbcc7EB129e0De3283c348B82c3'
    },
    tokenSymbol: 'EBEN',
    tokenAddresses: {
      10000: '0x77CB87b57F54667978Eb1B199b28a0db8C8E1c0B',
    },
    priority: 9999,
    quoteTokenSymbol: QuoteToken.BCH,
    quoteTokenAdresses: contracts.wbch,
  },
  {
    pid: 1,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'EBEN',
    lpAddresses: {
      10000: '0x0D4372aCc0503Fbcc7EB129e0De3283c348B82c3', // EBEN-BCH LP
    },
    tokenSymbol: 'EBEN',
    tokenAddresses: {
      10000: '0x77CB87b57F54667978Eb1B199b28a0db8C8E1c0B',
    },
    priority: 9999,
    quoteTokenSymbol: QuoteToken.BCH,
    quoteTokenAdresses: contracts.wbch,
  },
  {
    pid: 2,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'WBCH',
    lpAddresses: {
      10000: '0x0D4372aCc0503Fbcc7EB129e0De3283c348B82c3', // EBEN-BCH LP
    },
    tokenSymbol: 'WBCH',
    tokenAddresses: {
      10000: '0x3743eC0673453E5009310C727Ba4eaF7b3a1cc04',
    },
    priority: 10,
    quoteTokenSymbol: QuoteToken.BCH,
    quoteTokenAdresses: contracts.wbch,
  },
  {
    pid: 5,
    risk: 3,
    lpSymbol: 'flexUSD-SBCH LP',
    lpAddresses: {
      10000: '0x65C042E455a6B84132c78E8FDaE058188e17c75A'
    },
    tokenSymbol: 'SBCH',
    tokenAddresses: {
      10000: '0x3743eC0673453E5009310C727Ba4eaF7b3a1cc04',
    },
    priority: 100,
    quoteTokenSymbol: QuoteToken.flexUSD,
    quoteTokenAdresses: contracts.flexUSD,
  },
].sort((a,b)=>{
  return (b.priority||0) - (a.priority||0)
})

export default farms