import Web3 from 'web3'
import BigNumber from 'bignumber.js'
import { provider as ProviderType } from 'web3-core'
import { Contract } from 'web3-eth-contract'
import { AbiItem } from 'web3-utils'
import { GAS_PRICE } from 'config'
import erc20 from 'config/abi/erc20.json'
import abiPair from 'config/abi/pair.json'
import { isAddressMatch } from 'utils/addressHelpers'
import multicall from 'utils/multicall'

const gasPriceInObj = GAS_PRICE?{gasPrice:GAS_PRICE}:{};

export const getLpDetail = async (lpAddress: string) => {
  try {
    const calls1 = [{
        address: lpAddress,
        name: 'totalSupply'
    }, {
        address: lpAddress,
        name: 'token0'
    }, {
        address: lpAddress,
        name: 'token1'
    }, {
        address: lpAddress,
        name: 'getReserves'
    }];
    const res1 = await multicall(abiPair, calls1);
  
    const detail = {
      address: lpAddress,
      totalSupply: new BigNumber(res1[0][0]._hex).div(new BigNumber(10).pow(18)),
      token0: res1[1][0],
      token1: res1[2][0],
      reserve0:  new BigNumber(res1[3][0]._hex),
      reserve1:  new BigNumber(res1[3][1]._hex)
    }

    const calls2 = [];
    [detail.token0, detail.token1].forEach((token: any) => {
        calls2.push({
            address: token,
            name: 'name'
          },
          {
            address: token,
            name: 'symbol'
          },
          {
            address: token,
            name: 'decimals'
          });
    });

    const [
        name0,
        symbol0,
        decimals0,
        name1,
        symbol1,
        decimals1
      ] = await multicall(erc20, calls2);

      detail.token0 = {address:detail.token0, name: name0, symbol: symbol0, decimals:decimals0};
      detail.token1 = {address:detail.token1, name: name1, symbol: symbol1, decimals:decimals1};

      return detail;
  } catch (e) {
    return {}
  }
}

export const validateLp = async(lpValidatorContract, targetAddress) => {
    try {
        return lpValidatorContract.methods.validate(targetAddress).call()
    } catch (err) {
        console.error(err)
        return false;
    }
}

export const baseTokens = [
  "0xbb1Fcb08961d7fc7ab58dC608A0448aa30E66269",   // bbBUSD
  "0xbbb3700F33fCb64437Dc28A7Beb6b21f5cC76FB9",   // bbUSDT
  "0xbb2A35cc3e3dDb679fe30A82051633bC822e4191",   // bbUSDC
  "0x3743eC0673453E5009310C727Ba4eaF7b3a1cc04",   // WBCH
  "0x77CB87b57F54667978Eb1B199b28a0db8C8E1c0B",   // EBEN
  "0xBc2F884680c95A02cea099dA2F524b366d9028Ba",   // bcUSDT
  "0xBc9bD8DDe6C5a8e1CBE293356E02f5984693b195",   // bcBCH
  "0x7b2B3C5308ab5b2a1d9a94d20D35CCDf61e05b72",   // flexUSD
  "0x0b00366fBF7037E9d75E4A569ab27dAB84759302",   // LAW
  "0x265bD28d79400D55a1665707Fa14A72978FA6043"    // CATS
];

export const sortLpTokens = (token0, token1) => {
  for(let i=0; i<baseTokens.length; i++) {
      if (isAddressMatch(token0.address, baseTokens[i])) return [token1, token0];
      if (isAddressMatch(token1.address, baseTokens[i])) return [token0, token1];
  }

  return [token0, token1];
}

export const smartBchDexes = {
  BENSWAP: "ben",
  MISTSWAP: "mist",
  COWSWAP: "cow",
  TANGOSWAP: "tango",
  ONEBCH: "onebch",
  TROPICALFINANCE: "tropical",
  EMBERSWAP: "ember",
  LAWSWAP: "law",
  VERSEDEX: "verse"
}

export const smartBchFactories = {
  [smartBchDexes.BENSWAP]: {
    address: "0x8d973bAD782c1FFfd8FcC9d7579542BA7Dd0998D",
    name: "BenSwap",
    lpSymbol: "Ben-LP",
    lpDecimals: 18,
    image: "/images/lpfinder/ben.png"
  },
  [smartBchDexes.MISTSWAP]: {
    address: "0x6008247F53395E7be698249770aa1D2bfE265Ca0",
    name: "MistSwap",
    lpSymbol: "Mist-LP",
    lpDecimals: 18,
    image: "/images/lpfinder/mist.png"
  },
  [smartBchDexes.COWSWAP]: {
    address: "0x72cd8c0B5169Ff1f337E2b8F5b121f8510b52117",
    name: "CowSwap",
    lpSymbol: "Cow-LP",
    lpDecimals: 18,
    image: "/images/lpfinder/cow.png"
  },
  [smartBchDexes.TANGOSWAP]: {
    address: "0x2F3f70d13223EDDCA9593fAC9fc010e912DF917a",
    name: "TangoSwap",
    lpSymbol: "Tango-LP",
    lpDecimals: 18,
    image: "/images/lpfinder/tango.png"
  },
  [smartBchDexes.ONEBCH]: {
    address: "0x3dC4e6aC26df957a908cfE1C0E6019545D08319b",
    name: "1BCH",
    lpSymbol: "1BCH-LP",
    lpDecimals: 18,
    image: "/images/lpfinder/1bch.png"
  },
  [smartBchDexes.TROPICALFINANCE]: {
    address: "0x138504000feaEd02AD75B1e8BDb904f51C445F4C",
    name: "Tropical Finance",
    lpSymbol: "Tropical-LP",
    lpDecimals: 18,
    image: "/images/lpfinder/daiquiri.png"
  },
  [smartBchDexes.EMBERSWAP]: {
    address: "0xE62983a68679834eD884B9673Fb6aF13db740fF0",
    name: "EmberSwap",
    lpSymbol: "Ember-LP",
    lpDecimals: 18,
    image: "/images/lpfinder/ember.png"
  },
  [smartBchDexes.LAWSWAP]: {
    address: "0x3A2643c00171b1EA6f6b6EaC77b1E0DdB02c3a62",
    name: "LawSwap",
    lpSymbol: "Law-LP",
    lpDecimals: 18,
    image: "/images/lpfinder/LAW.png"
  },
  [smartBchDexes.VERSEDEX]: {
    address: "0x16bc2B187D7C7255b647830C05a6283f2B9A3AF8",
    name: "Verse",
    lpSymbol: "Verse-LP",
    lpDecimals: 18,
    image: "/images/lpfinder/VERSE.png"
  }
};

export const getLpAddress = async (factoryContract, token1, token2) => {
  try {
    const address = await factoryContract.methods.getPair(token1, token2).call();
    const isValid = address && (new BigNumber(address.toLowerCase())).isGreaterThan(0);
    return isValid ? address : "";
  } catch(error) {
    console.error(error);
    return "";
  }
}
