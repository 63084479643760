import BigNumber from 'bignumber.js'
import erc20 from 'config/abi/erc20.json'
import masterchefABI from 'config/abi/masterchef.json'
import multicall from 'utils/multicall'
import { getMasterChefAddress, isAddressMatch } from 'utils/addressHelpers'
import { getFarmConfig } from 'utils/masterChefUtils'
import contracts from 'config/constants/contracts'
import { QuoteToken } from '../../config/constants/types'

const CHAIN_ID = process.env.REACT_APP_CHAIN_ID

const PRICE_PER_BCH_CACHE = {
  [contracts.wbch[CHAIN_ID].toLowerCase()]: new BigNumber(1)
};

const fetchFarms = async () => {
  const farmsConfig = await getFarmConfig();
  let tasks: any = [];
  const data: any = [];
  for(let i=0; i<farmsConfig.length; i++) {
    const farmConfig = farmsConfig[i];
    tasks.push((async () => {
      const lpAdress = farmConfig.lpAddresses[CHAIN_ID]
      const calls = [
        // Balance of token in the LP contract
        {
          address: farmConfig.tokenAddresses[CHAIN_ID],
          name: 'balanceOf',
          params: [lpAdress],
        },
        // Balance of quote token on LP contract
        {
          address: farmConfig.quoteTokenAdresses[CHAIN_ID],
          name: 'balanceOf',
          params: [lpAdress],
        },
        // Balance of LP tokens in the master chef contract
        {
          address: farmConfig.isTokenOnly ? farmConfig.tokenAddresses[CHAIN_ID] : lpAdress,
          name: 'balanceOf',
          params: [getMasterChefAddress()],
        },
        // Total supply of LP tokens
        {
          address: lpAdress,
          name: 'totalSupply',
        },
        // Token decimals
        {
          address: farmConfig.tokenAddresses[CHAIN_ID],
          name: 'decimals',
        },
        // Quote token decimals
        {
          address: farmConfig.quoteTokenAdresses[CHAIN_ID],
          name: 'decimals',
        },
      ]

      const [
        tokenBalanceLP,
        quoteTokenBlanceLP,
        lpTokenBalanceMC,
        lpTotalSupply,
        tokenDecimals,
        quoteTokenDecimals
      ] = await multicall(erc20, calls)

      let tokenAmount;
      let lpTotalInQuoteToken;
      let tokenPriceVsQuote;
      if(farmConfig.isTokenOnly){
        tokenAmount = new BigNumber(lpTokenBalanceMC).div(new BigNumber(10).pow(tokenDecimals));
        if(farmConfig.tokenSymbol === QuoteToken.USDT && farmConfig.quoteTokenSymbol === QuoteToken.USDT){
          tokenPriceVsQuote = new BigNumber(1);
        }else{
          const quoteTokenAmountInLP = new BigNumber(quoteTokenBlanceLP)
          .div(new BigNumber(10).pow(quoteTokenDecimals))
          const tokenAmountInLP = new BigNumber(tokenBalanceLP)
          .div(new BigNumber(10).pow(tokenDecimals))

          // tokenPriceVsQuote = new BigNumber(quoteTokenBlanceLP).div(new BigNumber(tokenBalanceLP));
          tokenPriceVsQuote = new BigNumber(quoteTokenAmountInLP).div(new BigNumber(tokenAmountInLP));
        }
        lpTotalInQuoteToken = tokenAmount.times(tokenPriceVsQuote);
      }else{
        // Ratio in % a LP tokens that are in staking, vs the total number in circulation
        const lpTokenRatio = new BigNumber(lpTokenBalanceMC).div(new BigNumber(lpTotalSupply))

        // Total value in staking in quote token value
        lpTotalInQuoteToken = new BigNumber(quoteTokenBlanceLP)
          .div(new BigNumber(10).pow(18))
          .times(new BigNumber(2))
          .times(lpTokenRatio)

        // Amount of token in the LP that are considered staking (i.e amount of token * lp ratio)
        tokenAmount = new BigNumber(tokenBalanceLP).div(new BigNumber(10).pow(tokenDecimals)).times(lpTokenRatio)
        const quoteTokenAmount = new BigNumber(quoteTokenBlanceLP)
          .div(new BigNumber(10).pow(quoteTokenDecimals))
          .times(lpTokenRatio)

        if(tokenAmount.comparedTo(0) > 0){
          tokenPriceVsQuote = quoteTokenAmount.div(tokenAmount);
        }else{
          tokenPriceVsQuote = new BigNumber(quoteTokenBlanceLP).div(new BigNumber(tokenBalanceLP));
        }
      }

      const [info, totalAllocPoint, emissionPerBlock] = await multicall(masterchefABI, [
        {
          address: getMasterChefAddress(),
          name: 'poolInfo',
          params: [farmConfig.pid],
        },
        {
          address: getMasterChefAddress(),
          name: 'totalAllocPoint',
        },
        {
          address: getMasterChefAddress(),
          name: 'emissionPerBlock',
        },
      ])

      const allocPoint = new BigNumber(info.allocPoint._hex)
      const poolWeight = allocPoint.div(new BigNumber(totalAllocPoint))

      return {
        ...farmConfig,
        dex: Number.isInteger(farmConfig.dex)?farmConfig.dex:0,
        tokenAmount: tokenAmount.toJSON(),
        tokenDecimals: new BigNumber(tokenDecimals).toNumber(),
        // quoteTokenAmount: quoteTokenAmount,
        lpTotalInQuoteToken: lpTotalInQuoteToken.toJSON(),
        tokenPriceVsQuote: tokenPriceVsQuote.toJSON(),
        poolWeight: poolWeight.toNumber(),
        multiplier: `${allocPoint.div(100).toString()}X`,
        depositFeeBP: info.depositFeeBP,
        emissionPerBlock: new BigNumber(emissionPerBlock).toNumber(),
        totalStakedAmount: (farmConfig.isTokenOnly ? tokenAmount : new BigNumber(lpTokenBalanceMC).div(new BigNumber(10).pow(18))).toNumber()
      }
    })());

    if (tasks.length >= 30) {   // For scalability concern
      data.push(...(await Promise.all(tasks)));
      tasks= [];
    }
  }
  if (tasks.length) data.push(...(await Promise.all(tasks)));

  // update token price
  let loop = 0;
  while (loop++<3) {
    const curLoop = loop;
    data.forEach((d:any)=>{
      const tokenId = String(d.tokenAddresses[CHAIN_ID]).toLowerCase();
      if (!d.isTokenOnly) {
        const quoteTokenId = String(d.quoteTokenAdresses[CHAIN_ID]).toLowerCase();
        const quoteTokenPriceChche = PRICE_PER_BCH_CACHE[quoteTokenId];
        if (isAddressMatch(tokenId, contracts.wbch[CHAIN_ID])) {
          // Only primary stable coins can be the base of wbch
          d.tokenPriceVsBch = new BigNumber(1);
          PRICE_PER_BCH_CACHE[quoteTokenId] = new BigNumber(d.tokenPriceVsBch).div(d.tokenPriceVsQuote);
        } else if (quoteTokenPriceChche){
          d.tokenPriceVsBch = new BigNumber(d.tokenPriceVsQuote).times(quoteTokenPriceChche);
          PRICE_PER_BCH_CACHE[tokenId] = new BigNumber(d.tokenPriceVsBch);
        } else if (curLoop>1 && PRICE_PER_BCH_CACHE[tokenId]) {
          d.tokenPriceVsBch = new BigNumber(PRICE_PER_BCH_CACHE[tokenId]);
          PRICE_PER_BCH_CACHE[quoteTokenId] = new BigNumber(d.tokenPriceVsBch).div(d.tokenPriceVsQuote);
        } else {
          d.tokenPriceVsBch = new BigNumber(0);
        }
        d.liquidityBch = d.tokenPriceVsBch.times(d.tokenAmount).times(2);
      } else {
        d.tokenPriceVsBch = new BigNumber(PRICE_PER_BCH_CACHE[tokenId] || 0);
        d.liquidityBch = d.tokenPriceVsBch.times(d.tokenAmount);
      }
    })
  }
  return data
}

export default fetchFarms
