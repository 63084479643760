import { createReducer } from '@reduxjs/toolkit'
import setRefreshStatus from './actions'


export interface griDexSwapState {
  readonly refresh: boolean
}

const initialState: griDexSwapState = {
  refresh:false
}

export default createReducer<griDexSwapState>(initialState, (builder) =>
  builder
    .addCase(setRefreshStatus, (state) => {
      return {...state,refresh:!state.refresh}
    })
)
