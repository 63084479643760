import BigNumber from 'bignumber.js/bignumber'

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

// export const BLOCKS_PER_YEAR = new BigNumber(5256000)    // 6s
export const BLOCKS_PER_YEAR = new BigNumber(5733818)   // 5.5s
export const BSC_BLOCK_TIME = 6
// export const GAS_PRICE = 1050000000
export const GAS_PRICE = null
